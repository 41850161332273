// import useLoginCheck from '../../hooks/UseLoginCheck';
// import { API } from '../../api/API';
// import { useDispatch, useSelector } from 'react-redux';
import { clearUser } from 'store/userAuthSlice';
import useKakaoLogin from 'hooks/UseKakaoLogin';
import HeaderTitle from 'component/Header/HeaderTitle';
import { Link } from 'react-router-dom';

const MyPage = () => {
  const kakaoLogin = useKakaoLogin();
  const meData = {};

  const onLogout = () => {
    dispatch(clearUser());
    kakaoLogin.logout();
  };

  return (
    <>
      <HeaderTitle title={'내 정보'} />
      <div className="mx-auto w-full max-w-[40rem] flex flex-col px-5 lg:px-0 h-full">
        <div className="flex flex-col justify-center items-center h-52 gap-2">
          <img className="w-16" src="/imgs/graphic/graphic-person.svg" />
          <h3 className="text-black text-xl font-bold">
            {meData?.data?.name ?? '사용자'} 님
          </h3>
          <h6 className="text-xs">ssar509@gmail.com</h6>
        </div>
        <div className="border-t border-solid border-zinc-400 mt-4">
          <ul>
            <li>
              <Link to="/notice/1">
                <div className="h-20 w-full border-b border-solid border-zinc-400 flex flex-col justify-center gap-1 relative">
                  <h4 className="text-base font-bold text-black">공지사항 1</h4>
                  <span className="text-xs text-orange-600">
                    현재 보유 캐시: 9,000
                  </span>
                  <img
                    src="/imgs/ic/arrow-right.svg"
                    className="w-4 h-4 absolute right-0"
                  />
                </div>
              </Link>
            </li>
            <li>
              <Link to="/notice/1">
                <div className="h-16 w-full border-b border-solid border-zinc-400 flex flex-col justify-center gap-1 relative">
                  <h4 className="text-sm font-bold text-black">
                    캐시 사용/충전 내역
                  </h4>
                  <img
                    src="/imgs/ic/arrow-right.svg"
                    className="w-4 h-4 absolute right-0"
                  />
                </div>
              </Link>
            </li>
            <li>
              <Link to="/notice/1">
                <div className="h-16 w-full border-b border-solid border-zinc-400 flex flex-col justify-center gap-1 relative">
                  <h4 className="text-sm font-bold text-black">
                    고객센터 (카카오채널)
                  </h4>
                  <img
                    src="/imgs/ic/arrow-right.svg"
                    className="w-4 h-4 absolute right-0 -rotate-45"
                  />
                </div>
              </Link>
            </li>
            <li>
              <div
                onClick={onLogout}
                className="h-16 w-full border-b border-solid border-zinc-400 flex flex-col justify-center gap-1 relative"
              >
                <h4 className="text-sm font-bold text-black">로그아웃</h4>
                <img
                  src="/imgs/ic/arrow-right.svg"
                  className="w-4 h-4 absolute right-0 -rotate-45"
                />
              </div>
            </li>
          </ul>
        </div>
      </div>
      {/* 로그아웃</button> */}
    </>
  );
};

export default MyPage;
