import React from 'react';
import { Link, useMatch } from 'react-router-dom';

const BottomNav = () => {
  const isActivated = path => {
    return useMatch(path) ? '' : 'saturate-0';
  };

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-300 pb-5 z-30">
      <div className="flex justify-around py-4 max-w-[40rem] mx-auto">
        <Link to="/">
          <div className={`text-center transition ${isActivated('/')}`}>
            <button className="focus:outline-none">
              <img
                className="mx-auto mb-1.5"
                src="/imgs/ic/bn-home-active.svg"
                alt="home"
              />
              <span className="lock text-xs text-[#E53700]">홈 피드</span>
            </button>
          </div>
        </Link>
        <Link to="/myreservation">
          <div
            className={`text-center transition ${isActivated('/myreservation')}`}
          >
            <button className="focus:outline-none">
              <img
                className="mx-auto mb-1.5"
                src="/imgs/ic/bn-list.svg"
                alt="home"
              />
              <span className="lock text-xs text-[#E53700]">예약내역</span>
            </button>
          </div>
        </Link>
        <Link to="/notice">
          <div className="text-center">
            <button
              className={`text-center transition ${isActivated('/notice')}`}
            >
              <img
                className="mx-auto mb-1.5"
                src="/imgs/ic/bn-notice.svg"
                alt="home"
              />
              <span className="lock text-xs text-[#E53700]">공지사항</span>
            </button>
          </div>
        </Link>
        <Link to="/mypage">
          <div className={`text-center transition ${isActivated('/mypage')}`}>
            <button className="focus:outline-none">
              <img
                className="mx-auto mb-1.5"
                src="/imgs/ic/bn-my.svg"
                alt="home"
              />
              <span className="lock text-xs text-[#E53700]">내 정보</span>
            </button>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default BottomNav;
