import React from 'react';
// import Footer from '../../component/Footer/Footer';
import BottomNav from 'component/BottomNav/BottomNav';
import { Outlet } from 'react-router-dom';

const BaseLayout = () => {
  return (
    <div className="flex flex-col min-h-screen w-full">
      <div className="w-full h-full flex flex-col pb-40 flex-grow">
        {/* {children} */}
        <Outlet />
      </div>
      {/* <div className="mt-auto h-full w-full bg-skyblue1 flex flex-col">
        <Footer />
      </div> */}
      <BottomNav />
    </div>
  );
};

export default BaseLayout;
