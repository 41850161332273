import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { API } from 'api/API';

const Header = () => {
  const { accessToken } = useSelector(state => state.userAuth);
  const { data: me } = API.useGetMe({ accessToken });

  // 포인트 값을 1000단위로 쉼표 넣어서 포맷팅
  const formatPoints = points => {
    return points?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  return (
    <>
      <div className="mx-auto w-full h-16 max-w-[40rem] bg-white px-5 lg:px-0 flex items-center justify-between">
        <Link to="/">
          <img src="/imgs/logo.svg" alt="logo" />
        </Link>
        <div className="flex gap-1.5 items-start">
          {accessToken ? (
            // 로그인 정보가 있을 경우 포인트 표시
            <>
              <img src="/imgs/ic/ic-cash.svg" alt="cash" />
              <p className="text-sm font-medium text-gray-800">
                {me?.data?.point ? formatPoints(me.data.point) : '0'}
              </p>
            </>
          ) : (
            // 로그인 정보가 없을 경우 "로그인" 버튼 표시
            <Link
              to="/login"
              className="text-sm font-medium text-primary hover:text-primary-dark"
            >
              로그인
            </Link>
          )}
        </div>
      </div>
    </>
  );
};

export default Header;
