import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { API } from '../../api/API';
// import useKakaoLogin from '../../hooks/UseKakaoLogin';
import useLoginCheck from '../../hooks/UseLoginCheck';
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import { loginUser } from '../../store/userAuthSlice';
import { Link } from 'react-router-dom';
import useKakaoLogin from '../../hooks/UseKakaoLogin';
const Login = () => {
  const location = useLocation();
  const [search] = useSearchParams();
  const code = search?.get('code');
  const redirect = search?.get('state');
  const nextLink = location.state?.nextLink || redirect || '/';

  const kakao = useKakaoLogin(nextLink);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  useLoginCheck(false, nextLink);
  const { mutateAsync: login } = API.useLogin();

  useEffect(() => {
    if (code && dispatch && navigate && nextLink) {
      login({ code }).then(res => {
        if (res.success) {
          dispatch(
            loginUser({
              accessToken: res.data.accessToken,
            }),
          );
          navigate(redirect, { replace: true });
        }
      });
    }
  }, [dispatch, navigate, nextLink, login, code, redirect]);

  return (
    <div className="w-full h-screen flex flex-col items-center justify-center bg-white">
      <div className="absolute top-0 w-full h-12 bg-white flex justify-between align-center mt-3 px-5 max-w-md mx-auto">
        {/* 뒤로가기 버튼 */}
        <img
          onClick={() => navigate('/')}
          src="/imgs/leftArrow.png"
          className="w-6 h-6"
        ></img>
        {/* 상단 제목 */}
        <div className="font-sans font-bold text-lg text-black mr-6">
          로그인
        </div>
        <div className=""></div>
      </div>

      {/* 메인 로그인 화면 */}
      <div className="max-w-md mx-auto w-full flex flex-col items-center justify-center">
        <img src="imgs/Password.png" className="w-16 h-16 mb-7"></img>
        {/* 상단 텍스트 */}
        <h1 className="text-xl font-bold mb-2">로그인이 필요합니다.</h1>
        <p className="text-gray-500 mb-6 text-center text-sm">
          아직 머하짐 회원이 아니라면, 아래 버튼을 누르고 <br />
          카카오로 간편하게 회원가입하세요!
        </p>

        {/* 카카오 로그인 버튼 */}
        <img
          className="w-[90%] max-w-md text-black font-semibold py-3 rounded-lg flex items-center justify-center"
          onClick={kakao.login}
          src="/imgs/kakao_login.png"
        ></img>

        {/* 홈으로 돌아가기 링크 */}
        <Link to="/" className="text-orange-600 font-bold mt-6">
          홈으로 돌아가기
        </Link>
      </div>
    </div>
  );
};
export default Login;
