import React from 'react';

const TimeSelector = ({ reservation, setReservation }) => {
  const morningSlots = ['10:00', '10:30', '11:00', '11:30'];
  const afternoonSlots = [
    '12:00',
    '12:30',
    '1:00',
    '1:30',
    '2:00',
    '2:30',
    '3:00',
    '3:30',
    '4:00',
    '4:30',
    '5:00',
    '5:30',
  ];

  // 시간 선택 시 날짜와 결합하여 startAt에 저장
  const handleTimeSelect = time => {
    if (reservation.date) {
      const [hours, minutes] = time.split(':');
      const selectedDate = new Date(reservation.date); // 선택한 날짜와 결합
      selectedDate.setHours(parseInt(hours, 10));
      selectedDate.setMinutes(parseInt(minutes, 10));
      selectedDate.setSeconds(0);

      setReservation(prev => ({
        ...prev,
        startAt: selectedDate, // Date 객체로 저장
      }));
    } else {
      alert('먼저 날짜를 선택해 주세요.');
    }
  };

  const isSelected = time => {
    if (reservation.startAt) {
      const selectedHours = reservation.startAt.getHours();
      const selectedMinutes = reservation.startAt.getMinutes();
      return (
        `${selectedHours}:${selectedMinutes < 10 ? '0' : ''}${selectedMinutes}` ===
        time
      );
    }
    return false;
  };

  return (
    <div className="time-selector">
      <div className="mb-5">
        <p className="text-lg font-regular text-gray-500 mb-3">오전</p>
        <div className="grid grid-cols-4 gap-2">
          {morningSlots.map(time => (
            <button
              key={time}
              onClick={() => handleTimeSelect(time)}
              className={`w-full h-10 border rounded-lg text-sm transition-colors ${
                isSelected(time)
                  ? 'bg-red-500 text-white'
                  : 'bg-white border-gray-300 text-black hover:bg-gray-200'
              }`}
            >
              {time}
            </button>
          ))}
        </div>
      </div>

      <div className="mb-5">
        <p className="text-lg font-regular text-gray-500 mb-3">오후</p>
        <div className="grid grid-cols-4 gap-2">
          {afternoonSlots.map(time => (
            <button
              key={time}
              onClick={() => handleTimeSelect(time)}
              className={`w-full h-10 border rounded-lg text-sm transition-colors ${
                isSelected(time)
                  ? 'bg-red-500 text-white'
                  : 'bg-white border-gray-300 text-black hover:bg-gray-200'
              }`}
            >
              {time}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TimeSelector;
