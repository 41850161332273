import React from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './CustomCalendar.css';

const CustomCalendar = ({ reservation, setReservation }) => {
  const handleDateChange = newDate => {
    // 날짜를 선택하면 reservation 상태의 date 값을 업데이트
    setReservation(prev => ({
      ...prev,
      date: newDate, // 선택한 날짜를 업데이트
    }));
  };

  return (
    <div className="calendar-container">
      <div className="flex items-center mb-4">
        <img
          src="/imgs/Calendar.png"
          alt="달력 아이콘"
          className="w-6 h-6 mr-2"
        />
        <h3 className="text-xl font-bold">날짜와 시간을 선택해 주세요.</h3>
      </div>
      <Calendar
        onChange={handleDateChange}
        value={reservation.date || new Date()} // 선택된 날짜를 표시
        calendarType="US"
        locale="ko-KR"
        formatDay={(locale, date) => date.getDate()}
        formatMonthYear={(locale, date) =>
          `${date.getFullYear()}.${date.getMonth() + 1}`
        }
      />
    </div>
  );
};

export default CustomCalendar;
