// import React, { Fragment, useState } from 'react';
// import { Bars3Icon } from '@heroicons/react/24/outline';
// import { UserCircleIcon } from '@heroicons/react/24/outline';
// import Drawer from '../Drawer/Drawer';
// import { CurrencyDollarIcon } from '@heroicons/react/24/outline';
import { useSelector } from 'react-redux';
import { API } from 'api/API';

const HeaderTitle = ({ showCash = true, title }) => {
  const { accessToken } = useSelector(state => state.userAuth);
  const { data: me } = API.useGetMe({ accessToken });

  return (
    <>
      <div className="mx-auto w-full h-16 max-w-[40rem] bg-white px-5 lg:px-0 flex items-center justify-between relative">
        <h2 className="text-xl font-bold">{title && title}</h2>
        <div className="flex gap-1.5 items-start">
          {showCash ? (
            <>
              <img src="/imgs/ic/ic-cash.svg" alt="cash" />
              <p className="text-xs font-bold">{me?.data?.point ?? 0}</p>{' '}
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      {/* <Drawer open={open} setOpen={setOpen} /> */}
    </>
  );
};

export default HeaderTitle;
