import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { API } from '../../api/API';
import CustomCalendar from './CustomCalendar';
import TimeSelector from './TimeSelector';
import PeopleSelector from './PeopleSelector';
import TermsAgreement from './TermsAgreement';
import Skeleton from 'react-loading-skeleton'; // 스켈레톤 UI 추가
import 'react-loading-skeleton/dist/skeleton.css'; // 스켈레톤 스타일 적용
import useLoginCheck from '../../hooks/UseLoginCheck';
import {
  classReservationValidator,
  freeReservationValidator,
  validateChecker,
} from 'utils/validator';
const ReservationForm = () => {
  const { id } = useParams();
  const { accessToken } = useSelector(state => state.userAuth);
  const navigate = useNavigate();
  useLoginCheck(true, `/reservation/${id}`); // 로그인 체크 추가
  const [reservation, setReservation] = useState({
    date: null,
    startAt: null,
    peopleNum: 1,
    course: null,
    refund: false,
  });
  const [refundOpen, setRefundOpen] = useState(false);
  const [loading, setLoading] = useState(true); // 로딩 상태 추가

  const { data: gymData, isLoading } = API.useGetGymDetails({
    gymId: id,
    accessToken,
  });
  const { mutateAsync: createReservation } = API.useCreateReservation();

  useEffect(() => {
    if (!isLoading) {
      setLoading(false); // 데이터가 로드되면 로딩 상태를 해제
    }
  }, [isLoading]);

  const onSubmit = () => {
    if (!reservation.date) {
      alert('날짜를 선택해 주세요.');
      return;
    }

    if (
      !reservation.startAt ||
      isNaN(new Date(reservation.startAt).getTime())
    ) {
      alert('시간을 선택해 주세요.');
      return;
    }

    if (reservation.peopleNum <= 0) {
      alert('인원은 1명 이상이어야 합니다.');
      return;
    }

    if (!reservation.refund) {
      alert('환불규정에 동의해주세요.');
      return;
    }

    let result;
    if (gymData?.data.type === 'class') {
      result = validateChecker(reservation, classReservationValidator);
    } else if (gymData?.data.type === 'free') {
      result = validateChecker(reservation, freeReservationValidator);
    }

    if (result.success) {
      createReservation({
        gymId: id,
        accessToken,
        startAt: new Date(reservation.startAt).toISOString(),
        peopleNum: parseInt(reservation.peopleNum),
        courseId: reservation.course?._id || '',
      }).then(res => {
        if (res.success) {
          alert('예약이 완료되었습니다.');
          navigate('/');
        } else {
          console.log(res);
          alert('예약에 실패하였습니다.');
        }
      });
    } else {
      alert(result.message);
    }
  };

  return (
    <div className="w-full max-w-[40rem] px-4 lg:px-8 flex flex-col mx-auto items-center">
      {loading ? (
        // 로딩 중일 때 스켈레톤 UI를 표시
        <>
          <div className="border-b-2 flex w-full justify-between items-center my-5 pb-3">
            <Skeleton height={40} width={200} />
            <Skeleton height={20} width={150} />
          </div>
          <Skeleton height={300} width="100%" />
          <Skeleton height={50} width="100%" className="mt-5" />
          <Skeleton height={50} width="100%" className="mt-5" />
          <Skeleton height={50} width="100%" className="mt-5" />
        </>
      ) : (
        <>
          <div className="w-full h-12 bg-white flex justify-between align-center mt-5">
            <img
              onClick={() => navigate('/')}
              src="/imgs/leftArrow.png"
              className="w-6 h-6"
            ></img>
            <div className="font-sans font-bold text-lg text-black mr-6">
              예약하기
            </div>
            <div className=""></div>
          </div>
          <div className="border-b-2 flex w-full justify-between items-center my-5 pb-3">
            <h1 className="text-2xl lg:text-4xl font-bold">
              {gymData?.data?.name}
            </h1>
            <h1 className="text-sm lg:text-xl text-gray-500">
              {gymData?.data?.address}
            </h1>
          </div>

          <CustomCalendar
            reservation={reservation}
            setReservation={setReservation}
          />

          {gymData?.data?.type === 'free' && (
            <div className="w-full mt-5">
              <TimeSelector
                reservation={reservation}
                setReservation={setReservation}
              />
            </div>
          )}

          <PeopleSelector
            reservation={reservation}
            setReservation={setReservation}
          />
          <TermsAgreement
            reservation={reservation}
            setReservation={setReservation}
            refundOpen={refundOpen}
            setRefundOpen={setRefundOpen}
            onSubmit={onSubmit}
          />
        </>
      )}
    </div>
  );
};

export default ReservationForm;
