import React from 'react';
import { Link } from 'react-router-dom';
import useLoginCheck from '../../hooks/UseLoginCheck';

const MyReservation = () => {
  const nextLink = '/my-reservation'; // 로그인 후 돌아갈 링크 설정
  useLoginCheck(false, nextLink); // 로그인 여부 체크

  // 임시 예약 데이터
  const currentReservations = [
    {
      id: 1,
      title: '멀티핏 짐',
      date: '5.19 (일) 11:00 ~ 12:00 (1시간)',
      location: '경기도 안양 · 자이유 헬스',
      status: '예약 확정 완료',
      people: 1,
    },
    {
      id: 2,
      title: '멀티핏 짐',
      date: '5.19 (일) 11:00 ~ 12:00 (1시간)',
      location: '경기도 안양 · 자이유 헬스',
      status: '예약 접수 완료',
      people: 1,
    },
  ];

  const pastReservations = [
    {
      id: 3,
      title: '멀티핏 짐',
      date: '5.19 (일) 11:00 ~ 12:00 (1시간)',
      location: '경기도 안양 · 자이유 헬스',
      people: 1,
    },
    {
      id: 4,
      title: '멀티핏 짐',
      date: '5.19 (일) 11:00 ~ 12:00 (1시간)',
      location: '서울 강남 · 엘리트 헬스',
      people: 1,
    },
    {
      id: 5,
      title: '멀티핏 짐',
      date: '5.19 (일) 11:00 ~ 12:00 (1시간)',
      location: '서울 강남 · 엘리트 헬스',
      people: 1,
    },
  ];

  return (
    <div className="w-full max-w-[40rem] mx-auto p-5">
      <h1 className="text-xl font-bold mb-4">예약내역</h1>

      {/* 현재 예약 */}
      <h2 className="text-lg font-bold mb-2">현재 예약</h2>
      {currentReservations.map(reservation => (
        <div
          key={reservation.id}
          className="border-b border-gray-300 py-3 flex justify-between items-center"
        >
          <Link to={`/reservation/${reservation.id}`} className="flex-1">
            <h3 className="font-bold text-lg">{reservation.title}</h3>
            <p className="text-gray-500 text-sm">{reservation.location}</p>
            <p className="text-gray-500 text-sm">
              <span role="img" aria-label="calendar">
                📅
              </span>{' '}
              {reservation.date}
            </p>
            <p className="text-gray-500 text-sm">
              <span role="img" aria-label="people">
                👤
              </span>{' '}
              {reservation.people}명
            </p>
          </Link>
          <p
            className={`text-sm font-bold ${
              reservation.status.includes('확정') ? 'text-red-500' : ''
            }`}
          >
            {reservation.status}
          </p>
        </div>
      ))}

      {/* 지난 예약 */}
      <h2 className="text-lg font-bold mt-6 mb-2">지난 예약</h2>
      {pastReservations.map(reservation => (
        <div
          key={reservation.id}
          className="border-b border-gray-300 py-3 flex justify-between items-center"
        >
          <Link to={`/reservation/${reservation.id}`} className="flex-1">
            <h3 className="font-bold text-lg">{reservation.title}</h3>
            <p className="text-gray-500 text-sm">{reservation.location}</p>
            <p className="text-gray-500 text-sm">
              <span role="img" aria-label="calendar">
                📅
              </span>{' '}
              {reservation.date}
            </p>
            <p className="text-gray-500 text-sm">
              <span role="img" aria-label="people">
                👤
              </span>{' '}
              {reservation.people}명
            </p>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default MyReservation;
