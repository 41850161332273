// import React, { useEffect, useRef, useState } from 'react';
import React from 'react';
// import { Link } from 'react-router-dom';
import SlideView from 'component/SlideView/SlideView';
// import { ArrowLeftCircleIcon } from '@heroicons/react/24/outline';
// import BaseLayout from 'layout/BaseLayout/BaseLayout';
// import { API } from 'api/API';
// import regionList from 'data/regionList';
import List from 'page/List/List';
import Header from '../../component/Header/Header';

const SlideItem = ({ link, image, _id }) => {
  if (link)
    return (
      <a key={_id} href={link} className="w-full max-w-[40rem] m-auto flex">
        <img
          alt="slide item"
          src={image}
          className="w-full h-full object-contain"
        />
      </a>
    );
  else
    return (
      <div key={_id} className="w-full max-w-[40rem] m-auto flex">
        <img
          alt="slide item"
          src={image}
          className="w-full h-full object-contain"
        />
      </div>
    );
};

const Main = () => {
  // const regionSelectorRef = useRef();
  // const [openCities, setOpenCities] = useState(false);
  // const [selectRegion, setSelectRegion] = useState(null);
  // const [cities, setCities] = useState([]);
  // const { data: cityData, isLoading: cityLoading } = API.useGetCities({
  //   regionCode: selectRegion?.label,
  // });
  // const { data: bannerData } = API.useGetBanners();

  // const regionScroll = step => {
  //   regionSelectorRef.current.scrollLeft += step;
  // };

  // const regionClick = region => {
  //   setOpenCities(false);
  //   setTimeout(
  //     () => {
  //       if (region !== selectRegion) {
  //         setSelectRegion(region);
  //       } else {
  //         setSelectRegion(null);
  //       }
  //     },
  //     openCities ? 500 : 0,
  //   );
  // };

  // useEffect(() => {
  //   if (selectRegion !== null && cityLoading === false && cityData?.success) {
  //     setOpenCities(true);
  //     setCities(cityData?.data?.cities);
  //   } else if (selectRegion === null) {
  //     setOpenCities(false);
  //     setCities([]);
  //   }
  // }, [cityLoading, selectRegion, cityData]);

  return (
    <>
      <Header />
      <div className="w-full mb-8">
        <SlideView
          renderer={SlideItem}
          data={[{ image: '/imgs/slide_1.png', _id: '1' }]}
          dotSize={4}
        />
      </div>
      <List />
      {/* <div className="w-full max-w-[40rem] h-20 mt-5 mx-auto flex flex-row justify-around hidden">
        <button
          onClick={() => regionScroll(-80)}
          className="mx-3 my-auto rounded-full hidden lg:block"
        >
          <ArrowLeftCircleIcon className="w-10 h-10 my-auto stroke-white3" />
        </button>
        <div className="w-full max-w-[52rem] h-full bg-white px-4 py-2 flex rounded-xl">
          <div
            ref={regionSelectorRef}
            className="w-full y-full overflow-x-scroll justify-start grid grid-flow-col-dense scrollbar-hide scroll-smooth"
          >
            {regionList.map(region => (
              <div key={region.id} className="w-[5rem] h-full flex">
                {region === selectRegion ? (
                  <button
                    onClick={() => regionClick(region)}
                    className="w-14 h-14 m-auto bg-skyblue2 flex rounded-xl"
                  >
                    <p className="text-white1 text-xl m-auto">{region.label}</p>
                  </button>
                ) : (
                  <button
                    onClick={() => regionClick(region)}
                    className="w-14 h-14 m-auto bg-white flex rounded-xl"
                  >
                    <p className="text-xl m-auto">{region.label}</p>
                  </button>
                )}
              </div>
            ))}
          </div>
        </div>
        <button
          onClick={() => regionScroll(80)}
          className="mx-3 my-auto rounded-full hidden lg:block"
        >
          <ArrowLeftCircleIcon className="w-10 h-10 my-auto stroke-white3 rotate-180" />
        </button>
      </div>
      <div className="px-5 hidden">
        <div
          className={`w-full max-w-[52rem] mx-auto bg-white2 rounded-xl flex px-4 py-4 ${
            openCities ? 'h-96 opacity-100 my-5' : 'h-0 opacity-0 my-0'
          } transition-all duration-500`}
        >
          {cities?.length > 0 ? (
            <div className="w-full grid grid-cols-3 xl:grid-cols-10 lg:grid-cols-8 sm:grid-cols-5 gap-y-4 justify-start justify-items-center place-content-start content-start scrollbar-hide overflow-y-scroll scroll-smooth">
              {cities?.map(city => (
                <div key={city._id} className="w-16 h-16 flex">
                  <Link
                    to={{
                      pathname: `/list/${selectRegion?.label}/${city?._id}`,
                      state: {
                        region: selectRegion,
                        city: city,
                      },
                    }}
                    className="w-full h-full m-auto bg-yellow1 flex rounded-xl text-xl"
                  >
                    <p className="text-xl m-auto">{city?.name}</p>
                  </Link>
                </div>
              ))}
            </div>
          ) : (
            <p className="text-3xl text-skyblue1 m-auto">
              해당 지역에 체육관이 없습니다
            </p>
          )}
        </div>
      </div> */}
    </>
  );
};

export default Main;
