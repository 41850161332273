const TermsAgreement = ({
  reservation,
  setReservation,
  refundOpen,
  setRefundOpen,
  onSubmit,
  gymData,
}) => {
  return (
    <div className="w-full mt-5 p-4 border-t">
      <div className="flex justify-between items-center">
        <p className="text-lg font-bold">이용금액</p>
        <p className="text-lg font-bold text-orange-600">
          {/* peopleNum, dayPoint, gymFee 값이 없을 때 기본값을 설정 */}
          {reservation.peopleNum ||
            1 *
              ((gymData?.data?.dayPoint || 0) +
                (gymData?.data?.gymFee || 0))}{' '}
          캐쉬
        </p>
      </div>

      {/* 약관 동의 및 환불 규정 */}
      <div className="flex items-center mt-3">
        <input
          type="checkbox"
          id="agree"
          checked={reservation.refund}
          onChange={() =>
            setReservation(prev => ({ ...prev, refund: !prev.refund }))
          }
          className="appearance-none w-5 h-5 border-2 border-gray-300 rounded-md checked:bg-orange-600 checked:border-orange-600 checked:focus:ring-transparent"
        />
        <label htmlFor="agree" className="ml-2 text-sm">
          이용약관 및 환불규정에 동의합니다.
        </label>

        <button
          onClick={() => setRefundOpen(!refundOpen)}
          className="ml-auto flex items-center text-sm text-gray-500 underline"
        >
          약관 읽기
          <img
            src="/imgs/downArrow.png"
            alt="약관 화살표"
            className="w-4 h-4 ml-1"
          />
        </button>
      </div>

      {/* 환불 규정 내용 */}
      {refundOpen && (
        <div className="mt-3 bg-gray-100 p-4 rounded-lg text-sm text-gray-600">
          <p>
            환불 규정 내용: 예약 취소는 24시간 전까지 가능합니다. 그 이후에는
            환불이 불가합니다.
          </p>
        </div>
      )}

      {/* 예약하기 버튼 */}
      <div className="mt-5">
        <button
          disabled={!reservation.refund}
          onClick={onSubmit}
          className={`w-full h-12 rounded-full text-white flex items-center justify-center ${
            reservation.refund ? 'bg-orange-600' : 'bg-gray-300 text-gray-600'
          }`}
        >
          <img
            src="/imgs/timeCircle.png"
            alt="예약 아이콘"
            className="w-5 h-5 mr-2"
          />
          <span className="leading-none">예약하기</span>
        </button>
      </div>
    </div>
  );
};

export default TermsAgreement;
